
   export const PROD_F_URL = 'https://www.oracleacademy.lk';
   export const PROD_IP = '';
   export const SERVER_URL = `https://apis.edulab.lk/oracleacademy/v1/`;
   export const BASE_ROUTE = "";


// const teachme = 'https://teachme.ceyentra.lk/new-leeds';
// export const PROD_F_URL = teachme;
// const PROD_URL = `https://teachme.ceyentra.lk/api/v1/`;
// const URL_TESTING = PROD_URL;
// const URL_REMOTE =  window.location.hostname.indexOf('teachme.ceyentra.lk') !== -1 ? URL_TESTING : PROD_URL;
// export const SERVER_URL = window.location.hostname.indexOf('localhost') !== -1 || window.location.hostname.indexOf('localhost:3') !== -1 ?  URL_TESTING  : URL_REMOTE;
// export const BASE_ROUTE = "/new-leeds-admin";


export const STORAGE_KEY_ACCESS_TOKEN = "access_token";
export const STORAGE_KEY_REFRESH_TOKEN = "refresh_token";
export const STORAGE_KEY_USER = "user";
export const STORAGE_KEY_USER_PRIVILEGE= "user";

export const STORAGE_KEY_INSTITUTE_PRIVILEGE = "Institute_privilege";
export const STORAGE_KEY_USER_TYPE = "user_type";
export const TEACHER_ID = "teacher_id";
export const CLASS_ID = "class_id";
export const COURSE_ID = "course_id";
export const TEACHER_NAME = "teacher_name";
export const LAST_SELECTION = "LastSelected";
export const TYPE_COURSE = "course";
export const TYPE_CLASS = "class";
export const PRIVILEGES = "privileges";
export const PRIVILEGES2 = "privileges_teacher";
export const URL = "url";

// zoom configuration
export const ZOOM_API_KEY = "CBDC4YXbSASZClDtRGsK0g";
export const ZOOM_ROLE = 1;
export const ZOOM_LANG = "en-US";

export const ALERT_TEXT = "Are you sure you want to proceed?";
export const ALERT_TEXT_STATUS_CHANGE = "Are you sure you want to change status of this class?";
export const ALERT_TEXT_STATUS_CHANGE2 = "Are you sure you want to change status of this lesson?";
export const ALERT_TEXT_DELETE_LESSON = "Are you sure you want to delete this lesson? This process cannot be reversed.";
export const ALERT_TEXT_DELETE_PAPPER = "Are you sure you want to delete this lesson? This process cannot be reversed.";
export const ALERT_TEXT_DELETE_TEACHER = "Are you sure you want to delete Teacher? This process cannot be reversed.";
export const ALERT_CHANGE_MEETING_TYPE = "Are you sure you want to end this meeting?";
export const ALERT_TEXT_CHANGE_STATE_TEACHER = "Are you sure you want to change status of this Teacher?";
export const ALERT_TEXT_DELETE_CLASS = "Are you sure you want to delete this Class? This process cannot be reversed.";
export const ALERT_TEXT_STATUS_CHANGE3 = "Are you sure you want to change status of this Course?";
export const SEND_SMS_TEXT = "Do you wish to send an SMS to all purchased students?";
export const LEAVE_MEETING_WARNING = "Do you need to end or leave the meeting?";
export const LEAVE_MEETING_START = "Do you need to start the meeting?";
export const ALERT_TEXT_STATUS_CHANGE_QUIZ = "Are you sure you want to change status of this quiz?";

export const MAX_ENROLL = "Max Enroll: 100";

export const NOT_LESSON_PRIVILEGE = "0";
export const NOT_Course_PRIVILEGE = "0";
export const NOT_SMS_PRIVILEGE = "0";
export const VIDEO_DURATION = 180;

export const MCQ = 'MCQ';
export const ESSAY = 'ESSAY';
export const FULL = 'FULL';
export const INSTALLMENTS = 'INSTALLMENTS';

